import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoot } from "@telegram-apps/telegram-ui";
import { TabbarM } from "../Tabbar_main/Tabbar";
import './AppruvePage.css';
import { ReactComponent as Icon } from './icon_t/icons8-tether.svg';
import { BASE_API_URL } from '../js/apiConfig';
import { initBackButton } from '@telegram-apps/sdk-react';  // Import the BackButton SDK

const postData = async (url = '', data = {}) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            mode: 'cors',
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status}. ${errorText}`);
        }

        return response.json();
    } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
        throw error;
    }
};

export const AppruvePage = () => {
    const location = useLocation(); // Hook to access location state
    const navigate = useNavigate();
    const { data } = location.state || {}; // Destructure the passed data
    const balance = location.state?.balance ?? 0; // Получаем баланс из переданного состояния

    // Initialize the back button
    useEffect(() => {
        const [backButton] = initBackButton();  // Init the back button from Telegram SDK


        backButton.show()

        backButton.on('click', () => {
            navigate(-1);  // Navigate back on back button click
        });




    }, [navigate]);

    const handleConfirm = async () => {
        try {
            alert("Транзакция будет произведена в течении 20 минут");

            // Perform POST request with the received data
            const response = await postData(`${BASE_API_URL}/v1/staking/create`, data);
            console.log('POST запрос выполнен успешно:', response);

            // Navigate back to the home page after a successful request
            navigate("/");
        } catch (error) {
            console.error('Ошибка при выполнении POST запроса:', error);
        }
    };

    const handleCancel = () => {
        navigate('/'); // Navigate back to the home page
    };

    return (
        <AppRoot>
            <div className="approve-container">
                <h2 className="title_a">Подтверждение депозита</h2>
                <div className="amount-section">
                    <span className="amount">{data?.staking_amount ?? '0.00'} USDT</span>
                    <div className="usdt-icon">
                        <Icon />
                    </div>
                </div>
                <p className="days">{data?.staking_duration ?? '80 дней'}</p> {/* Shows '80 дней', '120 дней', etc. */}
                <p className="notice">
                    Обратите внимание, что Вы можете воспользоваться функцией "Досрочный вывод"; в любое время, комиссия составляет 10% от суммы депозита.
                </p>
                <div className="details">
                    <div className="detail-item">
                        <span>Сумма депозита</span>
                        <span>{data?.staking_amount ?? '0.00'} USDT</span>
                    </div>
                    <div className="detail-item">
                        <span>Длительность депозита</span>
                        <span>{data?.staking_duration}</span> {/* Human-readable period */}
                    </div>
                    <div className="detail-item">
                        <span>Прибыль, %</span>
                        <span>{data?.profit_percentage ?? '0'}%</span> {/* Dynamic profit percentage */}
                    </div>
                    <div className="detail-item">
                        <span>Сумма к выводу </span>
                        <span>{data?.staking_amount ? (data.staking_amount * (1 + (data.profit_percentage / 100))).toFixed(2) : '0.00'} USDT</span>
                    </div>
                </div>
                <div className="button-section">
                    <button className="cancel-button_a" onClick={handleCancel}>Отменить</button>
                    <button className="confirm-button_a" onClick={handleConfirm}>Подтвердить</button>
                </div>
            </div>
            <TabbarM balance={balance} />
        </AppRoot>
    );
};