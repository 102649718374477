import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Импортируем useNavigate для обновления страницы
import './TransactionInfoPage.css';
import { AppRoot } from "@telegram-apps/telegram-ui";
import { TabbarM } from "../Tabbar_main/Tabbar"; // Добавьте стили для страницы
import { ReactComponent as DepositIcon } from '../Tabbar_main/icons/arrow_d.svg'; // Импорт иконки "Ввод"
import { useUserInfo } from "../js/userInfo";

import { BASE_API_URL } from '../js/apiConfig';
import {initBackButton} from "@telegram-apps/sdk-react";

export const TransactionInfo = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Используем для обновления страницы после запроса
    const { transaction, balance } = location.state || {}; // Получаем данные транзакции и баланс из переданного состояния
    const { username } = useUserInfo();
    const [isCheckingStatus, setIsCheckingStatus] = useState(false); // Состояние для кнопки проверки статуса
    const [status, setStatus] = useState(transaction?.status || ''); // Состояние для отображения текущего статуса транзакции

    // Функция для POST-запроса обновления статуса транзакции
    const handleCheckStatus = async () => {
        try {
            setIsCheckingStatus(true); // Блокируем кнопку на время запроса
            const response = await fetch(`${BASE_API_URL}/v1/transaction/update?id=${transaction.id}`, {
                method: 'GET', // Изменяем на GET
                headers: {
                    'accept': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }

            const data = await response.json();

            // Обновляем статус транзакции после получения данных
            setStatus(data.result?.message?.status || 'Статус не найден');
            navigate(0); // Перезагружаем страницу для отображения обновленных данных
        } catch (error) {
            console.error('Ошибка при проверке статуса транзакции:', error);
        } finally {
            setIsCheckingStatus(false); // Разблокируем кнопку
        }
    };
    useEffect(() => {
        const [backButton] = initBackButton();  // Init the back button from Telegram SDK


        backButton.show()

        backButton.on('click', () => {
            navigate(-1);  // Navigate back on back button click
        });




    }, [navigate]);

    const handleGoBack = () => {
        navigate(-1); // Возвращаемся на предыдущую страницу
    };

    if (!transaction) {
        return <p>Данные о транзакции недоступны</p>;
    }

    // Определяем отображаемый статус
    const displayStatus = status === 'pending'
        ? 'В обработке'
        : status === 'error'
            ? 'Ошибка'
            : 'Выполнен';

    return (
        <AppRoot>
            <div className="transaction-info-container">
                <div className="transaction-info-header">
                    <div className="transaction-icon">
                        <DepositIcon />
                    </div>
                    <h2>{transaction.type === 'DEPOSIT' ? 'Ввод' : 'Вывод'}</h2>
                    <h3>{transaction.amount} USDT</h3>
                </div>

                <div className="transaction-details">
                    <ul>
                        <li><strong>Никнейм:</strong> {username || 'Не указано'}</li>
                        <li><strong>Дата:</strong> {new Date(transaction.created_at * 1000).toLocaleString()}</li>
                        <li><strong>Сумма:</strong> {transaction.amount} USDT</li>
                        <li><strong>Адрес:</strong> {transaction.withdraw_address || 'Не указано'}</li>
                        <li><strong>Статус:</strong> {displayStatus}</li> {/* Отображаем статус */}
                    </ul>
                </div>

                <div className="transaction-status-check">
                    {transaction.type === 'WITHDRAW' ? (
                        <div className="button-section">
                        <button
                            className="confirm-button_a"
                            onClick={handleCheckStatus}
                            disabled={isCheckingStatus} // Деактивируем кнопку при запросе
                        >
                            {isCheckingStatus ? 'Проверка...' : 'Проверить Статус'}

                        </button>
                        <button
                            className="cancel-button_a"
                            onClick={handleGoBack} // Кнопка вернет пользователя на предыдущую страницу
                        >
                            Назад
                        </button>
                        </div>
                    ) : (
                        <button
                            className="check-status-button"
                            onClick={handleGoBack} // Кнопка вернет пользователя на предыдущую страницу
                        >
                            Назад
                        </button>
                    )}
                </div>
            </div>
            <TabbarM balance={balance} />
        </AppRoot>
    );
};