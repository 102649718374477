import React, { useState, useEffect } from 'react';
import { AppRoot, Input } from "@telegram-apps/telegram-ui";
import { ReactComponent as Icon } from './icon_t/icons8-tether.svg'; // Импорт основного SVG
import { ReactComponent as CopyIcon } from '../Tabbar_main/icons/copy_icon.svg'; // Импорт SVG для кнопки копирования
import './DepositPage.css'; // Подключение CSS
import { TabbarM } from "../Tabbar_main/Tabbar"; // Подключение CSS
import { useUserInfo } from "../js/userInfo";
import { useLocation } from 'react-router-dom';
import { SegmentedControl} from '@telegram-apps/telegram-ui'; // Предполагаем, что SegmentedControl доступен
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import { BASE_API_URL } from '../js/apiConfig';

export const DepositPage = () => {
    const [selectedMethod, setSelectedMethod] = useState('manual'); // Состояние для выбранного метода

    // Обработчик изменения метода оплаты
    const handleMethodChange = (method: string) => {
        setSelectedMethod(method);
    };

    const location = useLocation();
    const balance = location.state?.balance ?? 0; // Получаем баланс из переданного состояния
    const [address, setAddress] = useState<string>(''); // Состояние для адреса
    const { userId } = useUserInfo(); // Получаем id пользователя
    const [copySuccess, setCopySuccess] = useState<string>(''); // Сообщение после копирования

    // Получение адреса пользователя через API
    const fetchUserWalletAddress = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/user/wallets?tg_id=${userId}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Response from wallet API:', data); // Log the full response for debugging

            // Access the address correctly from the nested structure
            const walletAddress = data.result?.message?.TON?.address || '';
            setAddress(walletAddress); // Set the address after checking the correct path
        } catch (error) {
            console.error('Ошибка при получении адреса:', error);
        }
    };

    // Вызов функции при монтировании компонента
    useEffect(() => {
        fetchUserWalletAddress(); // Получаем адрес кошелька
    }, []);

    // Функция для копирования адреса в буфер обмена
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(address); // Копируем адрес
            setCopySuccess('Адрес скопирован!'); // Показываем сообщение об успехе
        } catch (error) {
            console.error('Ошибка при копировании:', error);
            setCopySuccess('Ошибка при копировании');
        }

        // Очищаем сообщение через 2 секунды
        setTimeout(() => {
            setCopySuccess('');
        }, 2000);
    };

    const handlecrypto = () => {
        const url = "https://t.me/wallet/start"; // Замените на нужный URL
        window.open(url, '_blank'); // Открывает URL в новой вкладке
    };

    return (
        <AppRoot>
            <div className="deposit-container">
                <span className="title_p">Пополнение</span>
                <p className="min-usdt">Мин. 100 USDT</p>
                <div className="amount-section_d">
                    <span className="balance-amount">
                        {balance !== undefined && balance !== null ? balance.toFixed(2) : '0.00'} {/* Проверяем значение перед вызовом toFixed */}
                    </span>
                    <div className="usdt-icon">
                        <Icon/>
                    </div>
                    <span className="usdt-text">USDT</span>
                </div>

                {/* Добавляем переключатель */}
                <div className="payment-method">
                    <SegmentedControl>
                        <SegmentedControlItem
                            onClick={() => handleMethodChange('manual')}
                            selected={selectedMethod === 'manual'}
                            className={`segmented-control-item ${selectedMethod === 'manual' ? 'selected' : ''}`}
                        >
                            Ручной
                        </SegmentedControlItem>
                        <SegmentedControlItem
                            onClick={() => handleMethodChange('interkassa')}
                            selected={selectedMethod === 'interkassa'}
                            className={`segmented-control-item ${selectedMethod === 'interkassa' ? 'selected' : ''}`}
                        >
                            Банковская карта
                        </SegmentedControlItem>
                    </SegmentedControl>
                </div>
                <p className="notice">
                    Внимательно проверьте адрес кошелька и обязательно укажите свой ник (или номер) телеграм в
                    комментарии
                    платежа, чтобы система зачислила средства к Вам на аккаунт, в противном случае Ваши средства будут
                    утеряны.
                </p>

                {/* Рендеринг в зависимости от выбранного метода */}
                {selectedMethod === 'manual' ? (
                    <>
                        <div className="address-container">
                            <Input
                                disabled
                                header="Адрес для пополнения"
                                style={{fontSize: "12px"}}
                                placeholder={address ? address : 'Загрузка...'} // Показываем адрес для пополнения
                            />

                            {/* Кнопка копирования с SVG-иконкой */}
                            <button
                                className="copy-button_d"
                                onClick={copyToClipboard}
                                disabled={!address} // Disable button if address is not yet loaded
                            >
                                {/* SVG Icon instead of text */}
                                <CopyIcon width="24" height="24"/>
                            </button>
                        </div>

                        {/* Сообщение о результате копирования */}
                        {copySuccess && <p className="copy-success">{copySuccess}</p>}

                        <div className="network-section">
                            <label>Сеть</label>
                            <span>TON</span>
                        </div>
                        <button className="add-deposit-button" onClick={handlecrypto}>
                            Купить криптовалюту
                        </button>
                    </>
                ) : (
                    <div className="in-development">
                        <h2>В разработке</h2>
                    </div>
                )}
            </div>
            <TabbarM balance={balance}/>
        </AppRoot>
    );
};